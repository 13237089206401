@import 'scss/_variables.scss';

.link-to-download {
  color: $link-primary;
  cursor: pointer;
}

.link-to-download:hover {
  color: $link-primary-hover;
}

.min-width-500 {
  min-width: 500px;
}

.margin-bottom-2_5rem {
  margin-bottom: 2.5rem;
}

.margin-y-2_25rem {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.instruction {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.max-file-size {
  font-family: Source Sans Pro;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 410px;
  height: 190px;
}

.margin-x-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}

.dropzone-inactive {
  border-radius: 16px;
  border: 2px dashed $link-primary;
  background: var(--White, #fff);
}
.dropzone-active {
  border-radius: 16px;
  border: 2px solid $mainstay-dark-blue-65;
  background: var(--White, #fff);
}

.download {
  color: $link-primary;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 152.344%;
  text-transform: capitalize;
  transform: translateX(-9.5px);
}

.icon-font-size {
  font-size: 32px;
}

.org-attributes-row-container {
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    background-color: $mainstay-dark-blue-10;
  }
}
