@import 'scss/_variables.scss';

.kb-scraping-text-box {
  width: 100%;
  padding: 0px 10px 0px 0px;
  border: 2px solid $mainstay-dark-blue-80;
  border-radius: 5px;

  .text-counter {
    padding: 14px 50px !important;
  }

  .text-area {
    background-image: none !important;
  }
}

.kb-scraping-conversations {
  height: 70%;
  overflow: scroll;
  margin-bottom: 20px;
}

.section-divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid $mainstay-dark-blue-80;
}
