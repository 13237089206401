@import 'scss/_variables.scss';

.fancy-image-preview {
  position: relative;
  height: 30px;
  margin: 15px 16px;
  border: 1px solid $message-image-preview-border-color;
  border-radius: 3px;

  .image-container {
    .image {
      height: 28px;
      width: 28px;
      background-size: cover;
    }

    .image-name {
      display: flex;
      min-width: 0;
      align-items: center;
      font-size: 0.8125rem;
      max-width: 90%;
    }
  }

  .remove-button {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -10px;
    top: -10px;
  }
}
