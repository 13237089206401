@import 'scss/_variables.scss';

$animation-transition: all 200ms ease-in;

.wireframe-msg {
  .attribute-block {
    font-weight: 400 !important;
    font-size: 0.8rem;
  }
  &:not(.attribute-block) {
    font-weight: 600 !important;
    font-size: 1rem;
  }
  color: $mainstay-dark-blue !important;
}

.survey-row {
  display: flex;
  align-items: center;
  flex: 1 1 0px;
  justify-content: space-between;
}

.survey-label {
  flex-basis: 65%;
}

.mt-0_5 {
  margin-top: 0.5rem !important;
}

.survey-checkbox-container {
  margin-left: 2px;
}

.survey-checkbox-enter {
  transform: scale(0%, 0%);
}

.survey-checkbox-enter-active {
  transform: scale(100%, 100%);
  transition: $animation-transition;
}

.survey-checkbox-exit {
  transform: scale(100%, 100%);
}

.survey-checkbox-exit-active {
  transform: scale(0%, 0%);
  transition: $animation-transition;
}

.survey-question-enter {
  // width of checkbox component plus padding/margin
  transform: translateX(-46px);
}

.survey-question-enter-active {
  transform: translateX(0px);
  transition: $animation-transition;
}

.survey-question-exit {
  transform: translateX(0px);
}

.survey-question-exit-active {
  // width of checkbox component plus padding/margin
  transform: translateX(-46px);
  transition: $animation-transition;
}

.survey-report-download-enter {
  opacity: 0;
}
.survey-report-download-enter-done {
  opacity: 1;
}

.survey-report-download-enter-active {
  opacity: 1;
  transition: $animation-transition;
}

.survey-report-download-exit {
  opacity: 1;
}

.survey-report-download-exit-active {
  opacity: 0;
  transition: $animation-transition;
}

.survey-report-download-exit-done {
  opacity: 0;
}

.survey-data-enter,
.survey-data-enter-done,
.survey-data-enter-active {
  flex-basis: 30%;
  transition: $animation-transition;
}

.survey-data-exit,
.survey-data-exit-active,
.survey-data-exit-done {
  flex-basis: 35%;
  transition: $animation-transition;
}

.export-survey-btn {
  // align the button with the survey results header
  transform: translateY(-8px);
}

.workflow-responses-enter {
  transform: translateY(-44px);
}

.workflow-responses-enter-done {
  transform: translateY(0px);
}

.workflow-responses-enter-active {
  transform: translateY(0px);
  transition: $animation-transition;
}

.workflow-responses-exit {
  transform: translateY(0px);
}

.workflow-responses-exit-active {
  transform: translateY(-50px);
  transition: $animation-transition;
}

.workflow-responses-exit-done {
  transform: translateY(-50px);
}
