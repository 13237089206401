// utility classes that should follow in a similar style to Bootstrap's
// see: https://getbootstrap.com/docs/4.1/utilities/borders/

// In general, utilities should have an `!important` as they are declarative and
// shouldn't be overridden. General styling shouldn't use `!important`

@import '_variables.scss';
@import '_iconfont/iconfont_variables.scss';

.min-height-100 {
  min-height: 100% !important;
}
.fs-0_8rem,
.badge {
  font-size: 0.8rem;
}

.pseudo-link {
  color: $admithub-primary !important;
  &:hover {
    text-decoration: underline;
  }
}

.height-auto {
  height: auto !important;
}

.height-min-content {
  height: min-content !important;
}

.height-50vh {
  height: 50vh !important;
}

.height-100 {
  height: 100% !important;
}

.width-0 {
  width: 0 !important;
}

.width-50 {
  width: 50% !important;
}

.width-75 {
  width: 75% !important;
}

.width-100 {
  width: 100% !important;
}

.height-100vh {
  height: 100vh !important;
}

.height-1_5rem {
  height: 1.5rem !important;
}

.height-77px {
  height: 77px !important;
}

.height-100px {
  height: 100px !important;
}

.height-250px {
  height: 250px !important;
}

.max-page-height {
  height: calc(100vh - #{$nav-height}) !important;
}

.triage-max-page-height {
  height: calc(100vh - #{$triage-nav-height}) !important;
}

.overflow-y-auto,
.scroll-y {
  overflow-y: auto !important;
}

.scroll-x {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.line-height-0 {
  line-height: 0rem;
}

.line-height-normal {
  line-height: normal !important;
}

.line-height-22-6 {
  line-height: 22.6px !important;
}

.line-height-30 {
  line-height: 30px !important;
}

.line-height-100 {
  line-height: 100% !important;
}

.line-height-140 {
  line-height: 140% !important;
}

.icon-height-adjust {
  vertical-align: -2px;
}

.border-dashed {
  border-style: dashed !important;
}

.bw-2px {
  border-width: 2px !important;
}

.bw-4px {
  border-width: 4px !important;
}

.border-bottom-left-radius-3px {
  border-bottom-left-radius: 3px;
}

.border-radius {
  border-radius: $border-radius;
}

.border-radius-lg {
  border-radius: $lg-radius !important;
}

.border-radius-md {
  border-radius: $md-radius !important;
}

.border-radius-05em {
  border-radius: 0.5rem !important;
}

.border-radius-1em {
  border-radius: 1rem !important;
}

.border-radius-10 {
  border-radius: 0.625rem !important;
}

.border-radius-75 {
  border-radius: 7.5rem !important;
}

.border-radius-8px {
  border-radius: 8px;
}

.border-radius-top-8px {
  border-radius: 8px 8px 0px 0px;
}

@each $position in 'top-left', 'top-right', 'bottom-left', 'bottom-right' {
  .border-#{$position}-radius-lg {
    border-#{$position}-radius: $lg-radius !important;
  }
  .border-#{$position}-radius-md {
    border-#{$position}-radius: $md-radius !important;
  }
  .border-#{$position}-radius-0 {
    border-#{$position}-radius: 0 !important;
  }
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0 !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-40 {
  width: 40% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-content {
  width: fit-content !important;
}

@each $w in 40px, 60px 120px, 160px, 180px, 200px {
  .w-#{$w} {
    width: $w !important;
  }
}

.w-95vw {
  width: 95vw;
}

.w-360 {
  width: 360px !important;
}

.max-w-360 {
  max-width: 360px !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.pointer:hover {
  cursor: pointer !important;
}

.not-allowed:hover {
  cursor: not-allowed !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.no-highlight {
  user-select: none !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.overflow-wrap {
  width: 100%;
  overflow-wrap: break-word;
}

.text-wrap {
  text-wrap: wrap;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-muted-v2 {
  /* Defaults / Muted Text (BG60) */
  color: #5e6b72 !important;
}

.border-color-bluegrey-20 {
  border-color: #d6e0e6 !important;
}

.border-color-bluegrey-30 {
  border-color: $blue-grey-030 !important;
}

$amounts: 100px, 150px, 250px, 300px, 700px;

@each $amount in $amounts {
  .max-width-#{$amount} {
    max-width: $amount !important;
  }

  .max-height-#{$amount} {
    max-height: $amount !important;
  }
}

.max-height-100vh {
  max-height: 100vh !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.max-width-250 {
  max-width: 250px !important;
}

.max-width-200 {
  max-width: 200px !important;
}
.max-width-300 {
  max-width: 300px !important;
}

.max-width-350 {
  max-width: 350px !important;
}

.max-width-400 {
  max-width: 400px !important;
}

.max-width-450 {
  max-width: 450px !important;
}

.max-width-500 {
  max-width: 500px !important;
}

.max-width-800 {
  max-width: 800px !important;
}

.image-60 {
  height: 60px;
  width: 60px;

  .image {
    height: 28px;
    width: 28px;
    background-size: cover;
  }
}

.image-30 {
  height: 30px;
  width: 30px;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-2_5rem {
  height: 2.5rem !important;
}
.h-2_5rem {
  height: 2.5rem !important;
}

.max-h-200px {
  max-height: 200px !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-2_5rem {
  width: 2.5rem !important;
}

.px-7px {
  padding-top: 7px;
  padding-bottom: 7px;
}

.pl-_75rem {
  padding-left: 0.75rem;
}

.pr-_75rem {
  padding-right: 0.75rem;
}

.pt-_75rem {
  padding-top: 0.75rem;
}

.pb-_75rem {
  padding-bottom: 0.75rem;
}

.mr-4rem {
  margin-right: 4rem !important;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}

.mb-1_25rem {
  margin-bottom: 1.25rem !important;
}

.mb-1_75rem {
  margin-bottom: 1.75rem !important;
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.mb-3rem {
  margin-bottom: 3rem !important;
}

// tmoliter: this is very suspect
.mb-6rem {
  margin-bottom: 5rem !important;
}

.mt-1_25 {
  margin-top: 1.25rem !important;
}

.mt-2rem {
  margin-top: 1rem !important;
}

.mt-2rem {
  margin-top: 2rem !important;
}

.mt-_1 {
  margin-top: 0.1rem !important;
}

.mt-negative {
  margin-top: -0.25rem !important;
}

@each $name, $color in $theme-colors {
  hr.horizontal-line-#{$name} {
    border-top: 1px solid $color !important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@each $name, $color in $theme-colors {
  .border-color-#{$name} {
    border-color: $color !important;
  }
}

/*
  Generates bootstrap-style utility classes for SVG stroke colors.
*/

@each $name, $color in $theme-colors {
  .stroke-#{$name} {
    stroke: $color !important;
  }

  .color-#{$name} {
    color: $color !important;
  }

  .fill-#{$name},
  .fill-#{$name} g,
  .fill-#{$name} path {
    fill: $color !important;
  }
}

/*

  Generates bootstrap-style utility classes for hover colors.

  Usage: append a theme color to one of the .hover-bg-, .hover-border-, or .hover-text- utility classes.
  You can also wrap child elements in a .hover-parent container so that the children have hover styling
  applied to them when the parent is hovered over.

*/
@each $name, $color in $theme-colors {
  .hover-bg-#{$name}:hover {
    background-color: $color !important;
  }

  .hover-border-#{$name}:hover {
    border-color: $color !important;
  }

  .hover-text-#{$name}:hover {
    color: $color !important;
  }

  .hover-parent:hover {
    .hover-bg-#{$name} {
      background-color: $color !important;
    }

    .hover-border-#{$name} {
      border-color: $color !important;
    }

    .hover-text-#{$name} {
      color: $color !important;
    }
  }
}

.border-none {
  border: none;

  &:focus {
    border: none !important;
  }
}

.border-bottom-none {
  border-bottom: none !important;
}

@each $i in 0, 25, 33, 50, 65, 75, 80 {
  .opacity-#{$i} {
    opacity: $i * 0.01;
  }
}

.hover-opacity-100:hover {
  opacity: 1 !important;
}

.hover-out-opacity-5 {
  opacity: 0.5 !important;
}

.disabled-opacity-100 {
  &:disabled {
    opacity: 1 !important;
  }
}

// Generate `.bg-gray-*` color utilities
// see: https://github.com/twbs/bootstrap/blob/da717b03e6e72d7a61c007acb9223b9626ae5ee5/scss/_variables.scss#L25
@each $color, $value in $grays {
  .bg-gray-#{$color} {
    background-color: $value !important;
  }
}

.left-unset {
  left: unset !important;
}

@each $i in 0, 1 {
  .right-#{$i} {
    right: $i/4 + rem !important;
  }
}

.top-0 {
  top: 0 !important;
}

@each $weight in 400, 500, 600, 700 {
  .fw-#{$weight} {
    font-weight: $weight !important;
  }
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.hover-fw-400:hover {
  font-weight: 400 !important;
}

.avatar-box-shadow {
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.4) !important;
}

.shadow-border {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}

.shadow-top {
  box-shadow: 0px -3px 3px #eee !important;
}

.shadow-top-dark-spread {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 8px 22px rgba(0, 0, 0, 0.27);
}

.fs-14,
.fs-0875rem {
  font-size: 14px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-20px {
  font-size: 20px !important;
}

.fs-24px {
  font-size: 24px !important;
}

.fs-1_75rem {
  font-size: 1.75rem;
}

.fs-1_5rem {
  font-size: 1.5rem;
}

.fs-1_25rem {
  font-size: 1.25rem !important;
}

.fs-1rem,
.fs-16px {
  font-size: 1rem !important;
}

.fs-small,
.fs-13px {
  font-size: 0.8125rem;
}

.fs-18px {
  font-size: 1.125rem;
}

.fs-21px {
  font-size: 1.3125rem !important;
}

.fs-26px {
  font-size: 1.625rem !important;
}

.fs-32px {
  font-size: 2rem !important;
}

.fs-xsmall {
  font-size: 0.625rem;
}

.resize-none {
  resize: none !important;
}

.top-100 {
  top: 100% !important;
}

.transition-all {
  transition: $transition-base !important;
}

.word-wrap-break-word {
  word-wrap: break-word !important;
}

.word-break-break-word {
  word-break: break-word !important;
}

.min-width-100 {
  min-width: 100% !important;
}

.min-width-175px {
  min-width: 175px !important;
}

.min-width-200px {
  min-width: 200px !important;
}

.min-width-250px {
  min-width: 250px !important;
}

.min-width-350px {
  min-width: 350px !important;
}

.min-width-max-content {
  min-width: max-content !important;
}

.min-height-min-content {
  min-height: min-content !important;
}

.min-height-max-content {
  min-height: max-content !important;
}

.color-inherit {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

@each $size in 32px, 4rem, 7rem, 8rem, 12rem, 300px, 325px, 400px, 500px {
  .w-#{$size} {
    width: $size !important;
  }
  .mw-#{$size} {
    min-width: $size !important;
  }
}

// CSS helper util to show `child` element when `parent` is hovered
.hover-show-parent:not(:hover) {
  .hover-show-child {
    display: none !important;
  }

  .hover-show-child-invis {
    visibility: hidden !important;
  }
}

// CSS helper util to show `child` element when `parent` is hovered
.hover-opacity-100-parent:hover {
  .hover-opacity-100-child {
    opacity: 1 !important;
  }
}

.hover-shadow-sm:hover {
  box-shadow: $box-shadow-sm;
}

.shadow-md {
  box-shadow: $box-shadow !important;
}

// from https://github.com/primer/css/blob/63764f7edd59c5f4949c91e5373471aa8b0d4865/src/utilities/visibility-display.scss#L59-L74
// Only display content on focus
.show-on-focus {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  &:focus {
    z-index: 20;
    width: auto;
    height: auto;
    clip: auto;
  }
}

.hover-shadow-md:hover {
  box-shadow: $box-shadow;
}

.hover-shadow-lg:hover {
  box-shadow: $box-shadow-lg;
}

.bottom-inset-shadow {
  box-shadow: inset 0rem -0.5rem 0.5rem -0.5rem $blue-grey-100;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.white-space-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.link-unstyled {
  @include unstyled-link;
}

@each $side in left, right {
  .rounded-#{$side}-0 {
    border-top-#{$side}-radius: 0 !important;
    border-bottom-#{$side}-radius: 0 !important;
  }
}

.popper {
  z-index: 1000;
}

.z-index-unset {
  z-index: 0 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-100 {
  z-index: 100 !important;
}

.z-index-1000 {
  z-index: 1000 !important;
}

.background-none {
  background: none !important;
}

.background-white {
  background-color: $white;
}

.background-inherit {
  background-color: inherit !important;
}

.min-width-0 {
  min-width: 0 !important;
}

.no-transition {
  transition: unset !important;
}

.min-height-0 {
  min-height: 0 !important;
}

.min-height-100 {
  min-height: 100% !important;
}

// Text

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

// Buttons

// TODO (Alex)
// We should add global buttons utils elsewhere perhaps?
.btn-icon {
  cursor: pointer;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: $gray-600;
  @include transition();

  &:before {
    display: inline-block;
    line-height: 1;
  }

  &:hover {
    background-color: $gray-100;
    color: $gray-700;
  }
}

.btn-page-action {
  display: inline-flex;
  align-items: center;
  background-color: $white;
  box-shadow: $box-shadow-sm;
  padding: 0;
  padding-right: 1rem;
  line-height: 1;
  color: $blue-grey-600;
  border-radius: 3rem;
  margin-bottom: 2rem;

  &:before {
    font-family: '#{$icomoon-font-family}' !important;
    content: $icon-add;
    padding: 0.6rem 0.4rem 0.6rem 0.6rem;
    font-size: 2rem;
    color: $secondary-teal;
    margin-top: -0.12rem;
  }

  &:hover {
    box-shadow: $box-shadow;
  }
}

.btn-default {
  border: 2px solid $link-primary;
  color: $link-primary;
  background-color: transparent;
  &:hover {
    color: $link-primary-hover;
  }
}

.btn:focus {
  box-shadow: none !important;
  outline: -webkit-focus-ring-color auto 1px !important;
}

.border-2px {
  border-width: 2px !important;
}

.border-3px {
  border-width: 3px !important;
}

.border-radius-none {
  border-radius: 0;
}

.border-radius-50 {
  border-radius: 50%;
}

.border-radius-025rem {
  border-radius: 0.25rem;
}

// Type

// TODO (Alex)
// We should add global buttons utils elsewhere perhaps?
.caption {
  font-size: $font-size-xs;
  color: $mainstay-dark-blue-65;
}

.error {
  color: $new-ui-danger;
}

.text-inherit {
  color: inherit !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-underline-on-hover:hover {
  text-decoration: underline !important;
}

.flip-horizontal {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.gradient-background-blue {
  background-image: linear-gradient(
    270deg,
    #f2f9ff 62.01%,
    rgba(235, 246, 255, 0) 100%,
    #f2f9ff 100%
  );
}

// Create evenly-sized flex children independent of content. Name taken from relevent bootstrap github issue
.flex-even {
  flex: 1 1 0% !important;
}

.vertical-rule {
  height: 100%;
  width: 1px;
  border-left: 1px solid $blue-grey-030;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.background-image-none {
  background-image: none !important;
}

.border-bottom-2 {
  border-bottom-width: 2px;
}

.no-user-select {
  user-select: none !important;
}

.col-1-7 {
  max-width: calc(100% / 7);
}

.col-2-7 {
  max-width: calc(100% / 7 * 2);
}

.col-3-7 {
  max-width: calc(100% / 7 * 3);
}

.col-4-7 {
  max-width: calc(100% / 7 * 4);
}

@media (min-width: map-get($grid-breakpoints, xxl)) {
  .col-xl-1-7 {
    max-width: calc(100% / 7);
  }

  .col-xl-2-7 {
    max-width: calc(100% / 7 * 2);
  }

  .col-xl-3-7 {
    max-width: calc(100% / 7 * 3);
  }
}

.text-align-start {
  text-align: start !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-end {
  text-align: end !important;
}

.action-btn {
  text-align: left;
  .ahicon,
  span {
    color: $mainstay-dark-blue;
  }
  &:hover {
    color: $mainstay-dark-blue;
  }
}

.action-btn .ahicon {
  position: relative;
  top: 3px;
  margin-right: 8px;
}

.action-btn:focus-visible {
  box-shadow: none !important;
  outline: -webkit-focus-ring-color auto 1px !important;
}

.action-btn:focus {
  box-shadow: none;
  outline: none;
}

.input-label--error {
  color: $new-ui-danger !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.pe-none {
  pointer-events: none;
}

.margin-top-auto {
  margin-top: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.focus-mainstay-dark-blue {
  &:focus,
  &:focus-within {
    outline: auto 1px $mainstay-dark-blue !important;
  }
}

/* 2022 Style Guide Utils */
.mainstay-header-h6 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem !important;
  line-height: 1.24rem;
}

.mainstay-header-h5 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem !important;
  line-height: 1.5rem;
}

.mainstay-header-h4 {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem !important;
  line-height: 1.75rem;
}

.mainstay-header-h4-overview {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem !important;
  line-height: 1.5rem;
}

.mainstay-body-paragraph {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.25rem;
}

.mainstay-body-caption {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem !important;
  line-height: 1.25rem;
}

.mainstay-link {
  color: $link-primary;

  &:hover {
    cursor: pointer;
    color: $link-primary-hover;
    text-decoration: underline;
  }
}

.draggable {
  left: auto !important;
}

.hover-text-decoration-none {
  &:hover {
    text-decoration: none !important;
  }
}

.gap-2 {
  gap: $spacer * 0.5;
}

.gap-3 {
  gap: $spacer;
}

.gap-4 {
  gap: $spacer * 1.5;
}

.gap-5 {
  gap: $spacer * 3;
}

span.space-after::after {
  content: '\00a0';
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

h6.loading-ellipsis-after::after,
span.loading-ellipsis-after::after {
  content: '\2026';
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  width: 0px;
}

.search-input {
  max-width: 360px !important;
  .ahicon {
    color: $mainstay-dark-blue-65 !important;
  }
  input {
    color: $mainstay-dark-blue-80 !important;
    &::placeholder {
      color: $mainstay-dark-blue-50 !important;
    }
  }
}

.text-muted {
  color: $mainstay-dark-blue-65 !important;
}

.fg-white {
  color: white !important;
}

span.tag {
  @extend .text-ellipsis;
  @extend .fs-0_8rem;
  display: inline-block;
  background: #d8dee8;
  width: 32px;
  height: 16px;
  border-radius: 8px;
  line-height: 16px;
  color: $mainstay-gray-text;
  text-align: center;
}

.large-icon {
  font-size: 1.6em;
}

.med-icon {
  font-size: 1.4em;
}

.btn-link {
  color: $link-primary;
  &:hover {
    color: $link-primary-hover;
  }
}

.badge-pill {
  display: flex;
  width: fit-content !important;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 5.75px;
  align-self: stretch;
  border-radius: 75px;
  background-color: $mainstay-dark-blue-05;
  color: $mainstay-dark-blue-65;
}

.ms-action-menu {
  &:focus {
    background-color: $mainstay-dark-blue-10;
  }
  border-radius: 4px;
}

.no-max-width {
  max-width: unset !important;
}
