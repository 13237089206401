@import '../../scss/variables';

.campaign-calendar-month-header {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
}

.move-down-4px {
  transform: translateY(4px);
}

.month-header-dropdown {
  right: 0px;
  top: 4px;
}

.hide-outline {
  border: none;
  background-color: transparent;
  &:hover {
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }

  &:active {
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }

  &:focus-visible {
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }
}

.campaign-calendar-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;
}

.campaign-calendar-sidebar-description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
}

.campaign-calendar-section-icon {
  margin-bottom: 0.1rem;
}

.calendar-loading > .rbc-month-view {
  opacity: 0.5;
}

.rbc-event {
  color: unset;
  background-color: unset;
}

.rbc-event.rbc-selected {
  background-color: $mainstay-dark-blue-10 !important;
}

.rbc-today {
  background-color: unset;
}

.rbc-date-cell {
  padding: 0.5rem 0;
  text-align: left;

  button {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 2rem;
    justify-content: center;
    margin: 0 0.5rem;
    width: 2rem;
  }

  &.rbc-now button {
    background-color: #00a5af;
    color: white;
  }
}

.rbc-header {
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
}

.rbc-event-content {
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
}

.rbc-show-more {
  color: $admithub-primary !important;
  margin-left: 8px;
}

.date-selector-overlay {
  z-index: 1000;
  transform: translateX(-50%);
  margin-top: 14px;
  position: absolute;
  display: flex;
  width: 424px;
  padding: 16px 24px 32px 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background-color: #fff !important;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.date-selector-overlay-header {
  color: $mainstay-dark-blue-80;
  display: flex;
  align-items: center;
  text-align: center;
}

.date-selector-header-year {
  transform: translateY(1px);
}

.header-chevron-left {
  transform: scaleX(-1);
}

.date-selector-overlay-month {
  color: $mainstay-dark-blue-80;
  border-radius: 16px;
}

.month-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-button {
  width: fit-content;
  padding: 0.25rem 0.75rem;
}

.rbc-overlay {
  max-height: 500px;
  overflow-y: scroll;
}

.rbc-month-row {
  overflow-y: scroll;
}
