.filter-contacts-table {
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 9px;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
